import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
  nav {
    background-color: #e1eaf2;
    margin-bottom: 50px;
  }

  footer{
    color: #bacbda;
  }
`

const Div = styled.div`
  padding-bottom: 30px;

  .hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 61.8vh;
    max-height: 600px;

    text-align: center;
  }

  .heroImage {
    position: relative;
    height: 600px;
    width: 600px;
    max-height: 450px;
  }

  table {
    border: 1px solid black;
    box-sizing: border-box;
    border-spacing: 0;
    border-collapse: collapse;
  }

  td {
    border: 1px solid black;
  }

  @media (max-width: 600px) {
    .heroImage {
      width: 100%;
    }
  }
`

function Card({ post }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: post.Text.childMarkdownRemark.html,
      }}
    />
  )
}

function Reference({ post }) {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: post.About.childMarkdownRemark.html,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: post.People.childMarkdownRemark.html,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: post.Stories.childMarkdownRemark.html,
        }}
      />
      <div>
        {post.media.map((element) => {
          return (
            <img
              style={{ maxWidth: '50%', margin: '15px auto' }}
              src={element.file.url}
            />
          )
        })}
      </div>
    </>
  )
}

function CatalogueTemplate(props) {
  const post = props?.data?.contentfulCatalogue
  const [type, setType] = React.useState(null)

  React.useLayoutEffect(() => {
    setType(props.location.search)
  }, [props])

  const renderGroup = (t) => {
    switch (t) {
      case '?type=card':
        return <Card post={post} />
      case '?type=reference':
        return <Reference post={post} />
      default:
        return (
          <>
            <Card post={post} />
            <Reference post={post} />
          </>
        )
    }
  }

  return (
    <Layout location={props.location}>
      <GlobalStyle />
      <Div
        style={{
          background: '#e1eaf2',
          borderRadius: 5,
          boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
        }}
      >
        <Helmet title={`${post.First}`} />
        <div className="wrapper">
          <h1 className="section-headline">{post.First}</h1>
          {renderGroup(type)}
        </div>
      </Div>
      <Footer text="При финансовой поддержке Фонда президентских грантов, проект № 19-2-012668" />
    </Layout>
  )
}

export default CatalogueTemplate

export const pageQuery = graphql`
  query CatalogueBySlug($slug: String!) {
    contentfulCatalogue(First: { eq: $slug }) {
      First
      About {
        childMarkdownRemark {
          html
        }
      }
      People {
        childMarkdownRemark {
          html
        }
      }
      Stories {
        childMarkdownRemark {
          html
        }
      }
      Text {
        childMarkdownRemark {
          html
        }
      }
      media {
        file {
          url
        }
      }
      editor {
        name
      }
    }
  }
`
